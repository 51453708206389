import Model from '@/modules/sdk/core/model';
import TrackerModel from '@/models/tracker.model'

export default class AbbreviationModel extends Model {

  default() {
    return {
      caseSensitive: true,
      trackerentity: new TrackerModel(),
    }
  }

  relatedMap() {
    return {
      trackerentity: TrackerModel,
    }
  }

  columnCast() {
    return {
      caseSentivive: 'bool',
    };
  }
}
