<template>
  <ModalDialog
    v-model="visible"
    title="Export Options"
    icon="mdi-export"
    background-color="background"
    max-width="600"
    scrollable
    persistent
  >
    <template #body>
      <v-card class="mt-6">
        <v-card-text>

          <!-- ROWS -->
          <v-radio-group
            v-if="canSelectRows"
            v-model="selection"
            class="mt-0 mb-6"
            hide-details
            mandatory
            row
          >
            <v-radio
              :label="'All data sources (' + allRows.length + ')'"
              value="all"
            />
            <v-radio
              :label="'Selected data sources (' + selectedRows.length + ')'"
              :disabled="selectedRows.length === 0"
              value="selected"
            />
          </v-radio-group>

          <!-- HEADERS -->
          <v-autocomplete
            v-model="selectedHeaderList"
            :items="headers"
            label="Metadata"
            hint="All headers will be exported if none are selected."
            return-object
            persistent-hint
            clearable
            multiple
            outlined
            dense
          >
            <template #selection="{ index }">
              <span v-if="index === 0" v-text="$tc('rwdm.selectedColumnsTotal', selectedHeaderList.length, {
                total: selectedHeaderList.length
              })"></span>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </template>
    <template #actions>
      <v-spacer />
      <v-btn
        :disabled="!canExport"
        color="primary"
        large
        @click="onExportClick"
      >
        <span v-text="$t('btn.export')"></span>
      </v-btn>
      <v-btn
        outlined
        large
        @click="onCancelClick"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import ModalDialog from '@/modules/common/components/ModalDialog.vue'

export interface IExportOptions {
  format: 'csv' | 'json' | 'excel' | 'pdf',
  rows: {[key: string]: string}[],
  headers: any[],
}

@Component({
  components: {
    ModalDialog,
  }
})
export default class ExportOptionsModal extends Vue {
  @VModel({ type: Boolean, default: false }) visible!: boolean
  @Prop({ type: Array, default: () => ([]) }) headers!: any[]
  @Prop({ type: Array, default: null }) allRows!: {[key: string]: string}[]
  @Prop({ type: Array, default: null }) selectedRows!: {[key: string]: string}[]
  @Prop({ type: Boolean, default: false }) isPivoted!: boolean
  @Prop({ type: String, default: 'csv' }) format!: string

  selection: 'all' | 'selected' = 'all'
  selectedHeaderList: Array<string> = []

  onExportClick() {
    this.$emit('export', {
      format: this.format,
      rows: this.rows,
      headers: this.selectedHeaderList.length === 0
        ? this.headers
        : this.selectedHeaderList,
    })
  }

  onCancelClick() {
    this.visible = false;
  }

  get rows(): {[key: string]: string}[] {
    return this.selection === 'all'
      ? this.allRows || this.selectedRows
      : this.selectedRows || this.allRows;
  }

  get canSelectRows(): boolean {
    return !!(this.allRows && this.selectedRows);
  }

  get canExport(): boolean {
    return true;
  }
}
</script>
