<template>
  <div class="d-flex align-center py-2" style="gap: 0.5rem">
    <v-chip
      v-for="(diff, diffIdx) in getDifferences(firstValue, secondValue, header)"
      :key="diffIdx + '_' + diff"
      :color="
        diff.removed
          ? 'error'
          : diff.added
            ? 'success'
            : !diff.equals
              ? 'warning'
              : null
      "
      label
      small
    >
      <component :is="diff.removed ? 's' : 'span'">{{ diff.value }}</component>
    </v-chip>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import Utils from '@/modules/sdk/core/utils'
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IDiff, IDataHeader, IDefinition } from '@/interfaces';
import AbbreviationModel from '@/models/abbreviation.model';

@Component
export default class DiffChip extends Vue {

  @Prop({ type: Array, default: () => ([]) }) definitions!: Array<IDefinition>
  @Prop({ type: String, default: null }) firstValue!: string
  @Prop({ type: String, default: null }) secondValue!: string
  @Prop({ type: Object, default: null }) header!: IDataHeader
  @Prop({ type: Array, default: () => ([]) }) abbreviations!: Array<AbbreviationModel>
  @Prop({ type: Boolean, default: false }) showDiff!: boolean

  getDifferences(
    valueA: string,
    valueB: string,
    header: IDataHeader
  ): Array<IDiff> {
    const definition: IDefinition | undefined = this.definitions.find(definition => definition.name === header.text);
    if (definition && !definition.single) {
      const results: Array<IDiff> = [];
      const firstList = Utils.splitIgnoringCommasInQuotes(valueA);
      const secondList = Utils.splitIgnoringCommasInQuotes(valueB);

      firstList.forEach(firstItem => {
        const result: IDiff = results.find(result => result.value === firstItem) || {
          added: false,
          equals: secondList.includes(firstItem),
          removed: secondList.findIndex(secondItem => secondItem === firstItem) === -1,
          value: firstItem,
        };
        if (!results.find(item => item === result)) {
          results.push(result);
        }
      });

      secondList.forEach(secondItem => {
        const foundIdx = firstList.findIndex(firstItem => firstItem === secondItem);
        const result: IDiff = results.find(result => result.value === secondItem) || {
          added: foundIdx === -1,
          equals: firstList.includes(secondItem),
          removed: false,
          value: secondItem,
        };
        if (!results.find(item => item === result)) {
          results.push(result);
        }
      });

      return results.filter(item => this.showDiff || item.added || !item.removed);
    }

    return [{
      equals: valueA === valueB,
      value: valueB,
    }];
  }
}
</script>
