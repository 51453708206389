<template>
  <iframe v-if="!child" v-bind="$attrs" :src="src" ref="iframe" frameborder="0"></iframe>
  <div v-else v-bind="$attrs">
    <slot name="default" :props="data"></slot>
  </div>
</template>

<script lang="ts">
import { VModel, Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator';

export type IMessageContent = { [key: string]: any }

@Component
export default class IFrameCommunicator extends Vue {
  @Ref() iframe!: HTMLIFrameElement

  @VModel({ type: Object, default: () => ({}) }) props!: IMessageContent
  @Prop({ type: String, default: null }) src!: string
  @Prop({ type: Boolean, default: false }) child!: boolean

  data: IMessageContent = {}

  @Watch('props', { deep: true })
  onPropsChanged(props: IMessageContent) {
    this.sendMessage(props);
  }

  sendMessage(props: IMessageContent) {
    const text = JSON.stringify(props);
    this.iframe.contentWindow?.postMessage(text);
  }

  receiveMessage(event: MessageEvent) {
    if (typeof event.data === 'string') {
      try {
        const data = JSON.parse(event.data) as IMessageContent;
        this.data = data;
        this.$emit('data', data);
      } catch (e) {

      }
    }
  }

  mounted() {
    this.sendMessage(this.props);
  }

  created() {
    window.addEventListener('message', this.receiveMessage);
  }

  destroyed() {
    window.removeEventListener('message', this.receiveMessage);
  }
}
</script>
