<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'abbreviation'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="abbreviation"
      title="Abbreviation"
    >
      <template #form="{ formErrors, rules }">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="model.data.trackerId"
                :loading="trackerLoading"
                :items="trackerList"
                item-text="data.label"
                item-value="data.id"
                data-value="label"
                :error-messages="formErrors.trackerId"
                :rules="[]"
                outlined
                hint="Leave empty to make it global"
                :label="model.data.trackerId? 'Tracker' : ' - All Trackers -'"
                hide-details="auto"
                clearable
                required
                @input="delete formErrors.trackerId"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="model.data.name"
                :error-messages="formErrors.name"
                :rules="[rules.required]"
                outlined
                label="Name"
                hide-details="auto"
                clearable
                required
                @input="delete formErrors.name"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="model.data.description"
                :error-messages="formErrors.description"
                label="Description"
                outlined
                hide-details="auto"
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="model.data.caseSensitive"
                :error-messages="formErrors.caseSensitive"
                class="ma-0 pa-0"
                label="Is case sensitive?"
                outlined
                hide-details="auto"
                clearable
                inset
              />
            </v-col>
          </v-row>
        </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import TrackerService from '@/services/tracker.service'
import {Vue, Component} from 'vue-property-decorator';
import AbbreviationService from '@/services/abbreviation.service';
import AbbreviationModel from '@/models/abbreviation.model';
import DataForm from '@/modules/common/components/DataForm.vue';
import PresetManager from '@/modules/common/components/PresetManager.vue';

@Component({
  components: {
    DataForm,
    PresetManager,
  }
})
export default class AbbreviationForm extends Vue {

  service = AbbreviationService.getInstance();
  model: AbbreviationModel = new AbbreviationModel();
  loading = false;

  trackerList = [];
  trackerLoading = false;

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  loadRelations() {
    this.trackerLoading = true;
    TrackerService.getInstance().getAll()
      .then(response => this.trackerList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.trackerLoading = false);
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }
    this.loadRelations();
  }
}
</script>
