import 'reflect-metadata';
import Vue, { VueConstructor } from 'vue';
import App from './App.vue';
import Auth from './Auth.vue';
import router from './router';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import zemit from '@/plugins/zemit';
import VueHotkey from 'v-hotkey'
import PortalVue from 'portal-vue'
import ckeditor from '@/plugins/ckeditor';
import fullscreen from '@/plugins/fullscreen';
import Identity from '@/modules/sdk/core/identity';
import Filters from '@/modules/common/core/filters';
import Logger from '@/modules/sdk/core/logger';
import '@/modules/common/directives/test-id';
import '@/modules/common/directives/fixed-columns';
import '@/modules/common/directives/draggable';
import '@/modules/common/directives/debug';
import '@/modules/common/directives/context';
import '@/modules/common/directives/secure-url';
import '@/modules/common/directives/safechar';
import '@/modules/sdk/core/prototype';
import '@/modules/common/stylesheets/index.scss';
import './index.scss';

if (process.env.NODE_ENV === 'production' || process.env.VUE_APP_DISABLE_LOGGER === 'true') {
  Logger.enableProductionMode();
}
Vue.config.productionTip = false;

Filters.getInstance().init();

const d = new Logger('Main');

Vue.use(VueHotkey)
Vue.use(PortalVue)

export default class Main {

  static element = '#app';

  static options = {
    i18n,
    router,
    vuetify,
    zemit,
    ckeditor,
    fullscreen,
    render: (h: any) => h(Auth),
  };

  static $vue = Main.render(Identity.isLoggedIn() ? App : Auth);

  static init (options = this.options, elementOrSelector: Element | string = this.element, hydrating?: boolean): Vue {
    if (Main.$vue) Main.$vue.$destroy();
    Main.$vue = new Vue(options).$mount(elementOrSelector, hydrating)
    return Main.$vue;
  }

  static render(render: VueConstructor<any>, callback: CallableFunction | null = null, elementOrSelector: Element | string = this.element, hydrating?: boolean) {
    const vue = Main.init(Object.assign(this.options, {render: (h: any) => h(render)}), elementOrSelector, hydrating);
    callback && callback(vue);
    return vue;
  }

  static logout() {
    Main.render(Auth);
  }
}
