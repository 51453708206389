<template>
  <div class="d-flex flex-column h-100">

    <!-- EXPORT PDF OVERLAY -->
    <v-overlay :value="generating" z-index="1000">
      <div class="text-center">
        <h2>Generating {{ generating }}</h2>
        <v-progress-linear style="width: 15rem" class="my-3" indeterminate />
        <p>Please wait...</p>
      </div>
    </v-overlay>

    <!-- LOADING MAX HEADER -->
    <v-overlay v-if="loading || loadingDatabaseMaxHeader" color="white" absolute>
      <div class="d-flex flex-column align-center justify-center text-center" style="gap: 1rem">
        <v-progress-linear color="primary" style="max-width: 15rem" indeterminate/>
        <span class="text--disabled">Loading data...</span>
      </div>
    </v-overlay>

    <!-- NO DATA FOUND -->
    <div v-else-if="rows.length === 0" class="d-flex flex-column h-100 w-100 pa-4" style="flex: 1">
      <div class="w-100 pa-12 text-center h-100 d-flex align-center justify-center text-center" style="flex: 1; border: rgba(0, 0, 0, 0.2) dashed 3px; border-radius: 0.5rem">
        No data found
      </div>
    </div>

    <!-- DATA TABLE -->
    <v-data-table
      v-else
      v-model="selectedRows"
      v-fixed-columns
      :headers="headers"
      :items="rows"
      :loading="loading"
      :fixed-header="true"
      :height="height - 55"
      ref="datatable"
      item-key="Name of Data Source"
      show-select
      multi-sort
    >
      <!-- HEADERS -->
      <template v-slot:[th.slot]="{ header }" v-for="(th, thIdx) in thSlot">
        <v-tooltip v-if="header.text.length !== header.excerpt.length" :key="thIdx" bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ header.excerpt }}
            </span>
          </template>
          <span>{{ header.text }}</span>
        </v-tooltip>
        <span v-else :key="thIdx">{{ header.text }}</span>
      </template>

      <!-- FOOTER ACTIONS -->
      <template #footer.prepend>
        <div class="d-flex align-center" style="gap: 0.5rem">
          <v-btn color="primary" small :disabled="!canCompare" @click="onCompareClick">
            <v-icon small left>mdi-compare</v-icon>
            <span v-text="$t('btn.compare')"></span>
          </v-btn>
          <ExportRowsMenu
            v-model="selectedRows"
            :all-rows="rows"
            :title="selectedRows.length === 1 ? selectedRows[0]['Name of Data Source'] : tracker.data.label"
            :headers="headers"
            :definitions="definitions"
            :disabled="!canExport"
            outlined
            small
            @generating="onGenerating"
          />
          <div class="ml-4" v-html="$options.filters?.nl2br(tracker.data.footerNotes)">
          </div>
        </div>
      </template>

      <!-- CELLS -->
      <template v-for="(header, headerIdx) in parsedHeaders" #[header.slot]="{ item }">
        <div v-if="header.openMetadata" :key="header.slot" class="py-3">
          <div class="mb-2">{{ item[header.value] }}</div>
          <div class="d-flex align-center" style="gap: 0.5rem">
            <v-btn
              outlined
              x-small
              @click="() => onItemClick(item)"
            >
              View Metadata
            </v-btn>
            <InfoSourceActivator
              v-if="item['Info Source']"
              :value="item['Info Source']"
            />
          </div>
        </div>
        <TrackerViewCell
          v-else
          :key="header.slot"
          :value="item[header.value]"
          :header="{ text: header.value }"
          :row="item"
          :definitions="definitions"
          :abbreviations="abbreviations"
          :visible="headerIdx <= datatableMaxHeader"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import {Vue, Component, VModel, Prop, Watch} from 'vue-property-decorator';
import ExportRowsMenu from '@/components/ExportRowsMenu.vue';
import InfoSourceActivator from '@/components/InfoSourceActivator.vue';
import TrackerViewCell from '@/components/TrackerViewCell.vue';
import TrackerModel from '@/models/tracker.model';
import {IDefinition} from '@/interfaces';
import AbbreviationModel from '@/models/abbreviation.model';

let scrollDatatableTimeout: number;

@Component({
  components: {
    TrackerViewCell,
    InfoSourceActivator,
    ExportRowsMenu,
  }
})
export default class TrackerViewDatatable extends Vue {
  @VModel({ type: TrackerModel, default: () => new TrackerModel() }) tracker!: TrackerModel;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Array, default: () => ([]) }) rows!: Array<{[key: string]: string}>;
  @Prop({ type: Array, default: () => ([]) }) headers!: Array<any>
  @Prop({ type: Array, default: () => ([]) }) definitions!: IDefinition[]
  @Prop({ type: Array, default: () => ([]) }) abbreviations!: Array<AbbreviationModel>
  @Prop({ type: Number, default: 400 }) height!: number

  @Watch('loading')
  onLoadingChanged(loading: boolean) {
    if (!loading) {
      this.updateEvents();
    }
  }

  datatableMaxHeader = 10;
  generating = false;
  loadingDatabaseMaxHeader = false;
  selectedRows: Array<{[key: string]: string}> = []

  get thSlot(): Array<any> {
    return this.headers.map(header => ({
      ...header,
      slot: 'header.' + header.value,
    }))
  }

  get canExport(): boolean {
    return true;
  }

  get canCompare(): boolean {
    return this.selectedRows.length >= 2;
  }

  get parsedHeaders(): Array<any> {
    return this.headers.map(header => {
      const definition: IDefinition | undefined = this.definitions.find(definition => definition.name === header.text);
      header.slot = 'item.' + header.value;
      header.multiple = !!(definition && !definition.single);
      header.openMetadata = header.value === 'Name of Data Source'
      return header;
    });
  }

  onScrollDatatable(e: any, timeout = 50) {
    if (this.datatableMaxHeader < this.headers.length) {
      clearTimeout(scrollDatatableTimeout);
      scrollDatatableTimeout = setTimeout(() => {
        const table = e.target.parentElement;
        const scrollLeft = e.target.scrollLeft;
        let left = 0;
        table.querySelectorAll('thead tr th').forEach((th: any, thIdx: number) => {
          left += th.offsetWidth;
          if (left <= (scrollLeft + table.offsetWidth) && this.datatableMaxHeader < thIdx) {
            this.datatableMaxHeader = thIdx;
          }
        })
      }, timeout);
    }
  }

  onGenerating(value: any) {
    this.generating = value;
  }

  onCompareClick() {
    this.$emit('click:item', {
      rows: this.selectedRows,
    });
  }

  onItemClick(row: {[key: string]: string}) {
    this.$emit('click:item', {
      rows: [row],
    });
  }

  updateEvents() {
    this.datatableMaxHeader = 10;
    setTimeout(() => {
      if (this.$refs.datatable) {
        // @ts-ignore
        this.$refs.datatable.$el.children[0].addEventListener('scroll', this.onScrollDatatable)
        this.onScrollDatatable({
          // @ts-ignore
          target: this.$refs.datatable.$el.children[0]
        }, 0);
      }
    })
  }
}
</script>
