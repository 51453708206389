<template>
  <ModalDialog
    v-model="visible"
    :title="title"
    :max-width="syncedSelectDataSourceFirst ? 800 : filteredRows.length <= 1 ? 1000 : 1300"
    :icon="syncedSelectDataSourceFirst || filteredRows.length <= 1 ? null : 'mdi-compare'"
    background-color="background"
    scrollable
  >
    <!-- STICKY NAME OF DATA SOURCES HEADERS -->
    <template v-if="view === 'categorized' && hasMultipleRows" #header>
      <div class="px-12 pt-3 pb-6">
        <div class="d-flex align-center" style="gap: 1rem">
          <h3 v-if="filteredRows[indexA]" class="pl-4" :style="{ width: !hasMultipleRows ? '100%' : '50%' }">
            {{ filteredRows[indexA]['Name of Data Source'] }}
          </h3>
          <h3 v-if="filteredRows[indexB]" class="pl-3" :style="{ width: !hasMultipleRows ? '100%' : '50%' } ">
            {{ filteredRows[indexB]['Name of Data Source'] }}
          </h3>
        </div>
      </div>
      <v-divider />
    </template>

    <!-- HEADER: UPPER-RIGHT -->
    <template #close.prepend>
      <div v-if="showDiff" class="d-flex align-center text-no-wrap mr-6" style="gap: 0.5rem">
        <strong>Legend:</strong>
        <v-chip label small color="success">Additional</v-chip>
        <v-chip label small color="warning">Different</v-chip>
        <v-chip label small color="error">Missing</v-chip>
      </div>

      <v-sheet v-if="!syncedSelectDataSourceFirst" class="d-flex align-center" style="gap: 1rem">
        <v-btn-toggle v-model="view" color="primary" mandatory tile group>
          <v-btn value="tabular" small>
            <v-icon left>mdi-table</v-icon>
            <span>Tabular</span>
          </v-btn>
          <v-btn value="categorized" small>
            <v-icon left>mdi-table-column</v-icon>
            <span>Categorized</span>
          </v-btn>
        </v-btn-toggle>
      </v-sheet>
    </template>

    <!-- BODY -->
    <template #body>

      <!-- EXPORT PDF OVERLAY -->
      <v-overlay :value="generating" z-index="1000">
        <div class="text-center">
          <h2>Generating {{ generating }}</h2>
          <v-progress-linear style="width: 15rem" class="my-3" indeterminate />
          <p>Please wait...</p>
        </div>
      </v-overlay>

      <!-- SELECT DATA SOURCE -->
      <v-list v-if="syncedSelectDataSourceFirst" class="mt-4">
        <v-list-item-group
          v-model="selectedDataSources"
          multiple
        >
          <v-list-item
            v-for="(row, rowIdx) in filteredRows"
            :key="rowIdx"
          >
            <template #default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" color="primary"></v-checkbox>
              </v-list-item-action>
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-book-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="row['Name of Data Source']"></v-list-item-title>
                <v-list-item-subtitle v-text="row['Type of Data Source']"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <!-- TABULAR VIEW -->
      <template v-else-if="view === 'tabular'">
        <v-card>
          <v-data-table
            v-model="singleSelect"
            v-fixed-columns
            :headers="getComparedHeaders(filteredRows)"
            :items="pivotedTabularRows"
            :fixed-header="true"
            :show-select="!isPivoted && showDiff"
            :single-select="showDiff"
            :items-per-page="isPivoted ? -1 : 10"
            :hide-default-footer="isPivoted"
            :hide-default-header="isPivoted && !hasMultipleRows"
            :class="['mt-4 dt-compare', {
              pivoted: isPivoted
            }]"
            item-key="Name of Data Source"
            multi-sort
          >
            <template v-slot:[header.slot]="{ item }" v-for="(header, headerIdx) in headersSlot">
              <TrackerViewCell
                v-model="item[header.text]"
                :key="headerIdx"
                :definitions="definitions"
                :abbreviations="abbreviations"
                :firstValue="getSelectedItemToCompare(item, header)"
                :secondValue="item[header.text]"
                :header="isPivoted ? { text: item['Name of Data Source'] } : header"
                :row="item"
                :show-diff="showDiff"
                :style="!header ? 'color: var(--v-primary-base); font-weight: bold' : ''"
              />
            </template>
            <template v-slot:[cell.slot]="{ item }" v-for="(cell, cellIdx) in isPivoted ? [{ slot: 'item.Name of Data Source'}] : []">
              <span :key="cellIdx" class="font-weight-bold">{{ item['Name of Data Source'] }}</span>
            </template>
          </v-data-table>
        </v-card>
      </template>

      <!-- CATEGORIZED VIEW -->
      <template v-else-if="view === 'categorized'">

        <!-- PANELS -->
        <v-expansion-panels v-model="panels" multiple class="mt-4" light>
          <v-expansion-panel
            v-for="(category, categoryIdx) in categories"
            :key="categoryIdx"
            :style="{
              backgroundColor: categoryIdx > 0 ? '#' + colors[categoryIdx - 1] : null,
            }"
          >
            <v-expansion-panel-header>
              <span :class="{
                'font-weight-bold': panels.includes(categoryIdx),
              }" v-text="category.text || 'Uncategorized'"></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="d-flex align-start" style="column-gap: 1rem">
                <div
                  v-for="(row, rowIdx) in comparedRows"
                  :key="row['Name of Data Source'] + rowIdx"
                  class="h-100"
                  :style="{
                    width: hasMultipleRows ? '50%' : '100%',
                    minWidth: hasMultipleRows ? '50%' : '100%',
                    maxWidth: hasMultipleRows ? '50%' : '100%',
                    position: 'relative',
                  }"
                >
                  <v-divider v-if="rowIdx === 0 && hasMultipleRows" vertical style="height: 100%; width: 1px; position: absolute; right: 0" />
                  <v-list color="transparent">
                    <v-list-item
                      v-show="!hideSimilarities || ((filteredRows[indexA] && filteredRows[indexA][header.data.text]) !== (filteredRows[indexB] && filteredRows[indexB][header.data.text]))"
                      v-for="(header, headerIdx) in category.headers.filter(header => header.data.text !== 'Name of Data Source')"
                      :key="headerIdx"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="header.data.text"></v-list-item-title>
                        <v-list-item-subtitle class="text-wrap">
                          <TrackerViewCell
                            :definitions="definitions"
                            :abbreviations="abbreviations"
                            :firstValue="filteredRows[indexA] && filteredRows[indexA][header.data.text]"
                            :secondValue="filteredRows[indexB] && filteredRows[indexB][header.data.text]"
                            :header="header.data"
                            :row="row"
                            :show-diff="showDiff && rowIdx !== 0"
                          />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </template>

    <!-- CATEGORIZED NAVIGATION -->
    <template #footer v-if="view === 'categorized' && hasMultipleRows">
      <v-sheet class="d-flex align-center pa-4 w-100" color="primary" style="flex: 1" dark>
        <div class="d-flex align-center px-12" style="width: 50%; gap: 0.5rem">
          <v-btn :disabled="indexA === 0" outlined @click="() => indexA--">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <div class="px-4">
            <span v-text="indexA + 1"></span>
            of
            <span v-text="filteredRows.length"></span>
          </div>

          <v-btn :disabled="indexA === filteredRows.length - 1" outlined @click="() => indexA++">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>

        <div class="d-flex align-center px-4" style="width: 50%; gap: 0.5rem">
          <v-btn :disabled="indexB === 0" outlined @click="() => indexB--">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <div class="px-4">
            <span v-text="indexB + 1"></span>
            of
            <span v-text="filteredRows.length"></span>
          </div>

          <v-btn :disabled="indexB === filteredRows.length - 1" outlined @click="() => indexB++">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-sheet>
    </template>

    <!-- BUTTONS -->
    <template #buttons>
      <template>
        <div class="d-flex align-center" style="gap: 1rem">
          <ExportRowsMenu
            v-model="selectedRows"
            :all-rows="filteredRows"
            :headers="headers"
            :pivoted-rows="pivotedTabularRows"
            :pivoted-headers="getComparedHeaders(filteredRows)"
            :title="filteredRows.length > 1 ? (syncedTitlePrefix ? syncedTitlePrefix + ' - ' : '') + tracker.data.label : currentViewedItem['Name of Data Source']"
            :definitions="definitions"
            :disabled="!canExport"
            outlined
            @generating="onGenerating"
          />
          <template v-if="!syncedSelectDataSourceFirst">
            <v-select
              v-if="view === 'tabular'"
              v-model="selectedColumns"
              :items="headerFilters"
              :label="isPivoted ? 'Row(s)' : 'Column(s)'"
              style="width: 20rem"
              clearable
              multiple
              outlined
              hide-details
              dense
            >
              <template #selection="{ index }">
                <span v-if="index === 0" v-text="$tc('rwdm.selectedColumnsTotal', selectedColumns.length, {
                  total: selectedColumns.length
                })"></span>
              </template>
            </v-select>
            <template v-if="filteredRows.length > 1">
              <v-checkbox
                v-model="showDiff"
                :label="$t('rwdm.showDiff')"
                class="pa-0 mt-0"
                hide-details
              />
              <v-checkbox
                v-model="hideSimilarities"
                :label="$t('rwdm.hideSimilarities')"
                class="pa-0 mt-0"
                hide-details
              />
            </template>
          </template>
        </div>

        <v-spacer />

        <template v-if="!syncedSelectDataSourceFirst">

          <!-- EXPAND/COLLAPSE ALL -->
          <div v-if="view === 'categorized'" class="mr-6 d-flex align-center" style="gap: 1rem">
            <v-tooltip top>
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="panels.length === categories.length"
                  text
                  @click.stop="onExpandAllPanels"
                >
                  <v-icon>mdi-expand-all</v-icon>
                </v-btn>
              </template>
              <span>Expand all</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="panels.length === 0"
                  text
                  @click.stop="onCollapseAllPanels"
                >
                  <v-icon>mdi-collapse-all</v-icon>
                </v-btn>
              </template>
              <span>Collapse all</span>
            </v-tooltip>
          </div>

          <v-btn
            v-if="view === 'tabular'"
            outlined
            color="primary"
            @click="onPivotTable"
          >
            <v-icon left>mdi-rotate-orbit</v-icon>
            <span>Pivot</span>
          </v-btn>
          <v-btn
            v-if="view === 'categorized' && comparedRows.length > 1"
            outlined
            color="primary"
            @click="onFlipIndexes"
          >
            <v-icon left>mdi-flip-horizontal</v-icon>
            <span v-text="$t('btn.flip')"></span>
          </v-btn>
        </template>
      </template>
      <v-btn
        v-if="syncedSelectDataSourceFirst"
        :disabled="selectedDataSources.length === 0"
        color="primary"
        @click="() => onSelectDataSourceClick(selectedRows)"
      >
        <template v-if="selectedDataSources.length > 1">
          <v-icon left>mdi-compare</v-icon>
          <span v-text="$t('btn.compare')"></span>
        </template>
        <span v-else>View Metadata</span>
      </v-btn>
      <v-btn
        outlined
        @click="visible = false"
      >
        <span v-text="$t('btn.close')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop, PropSync, Watch } from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import TrackerViewCell from '@/components/TrackerViewCell.vue';
import ExportRowsMenu from '@/components/ExportRowsMenu.vue';
import { ICategory, IDefinition } from '@/interfaces';
import TrackerModel from '@/models/tracker.model';
import MenuTooltip from '@/modules/common/components/MenuTooltip.vue';
import AbbreviationModel from '@/models/abbreviation.model';

type IRow = {[key: string]: string}

@Component({
  components: {
    MenuTooltip,
    ExportRowsMenu,
    TrackerViewCell,
    ModalDialog,
  }
})
export default class TrackerViewCompareDialog extends Vue {
  @VModel({ type: Boolean, default: false }) visible!: boolean;
  @Prop({ type: Array, default: () => ([]) }) rows!: IRow[];
  @Prop({ type: Array, default: () => ([]) }) headers!: Array<any>
  @Prop({ type: Array, default: () => ([]) }) definitions!: IDefinition[]
  @Prop({ type: Array, default: () => ([]) }) abbreviations!: Array<AbbreviationModel>
  @Prop({ type: TrackerModel, default: () => new TrackerModel() }) tracker!: TrackerModel;
  @PropSync('titlePrefix', { type: String, default: '' }) syncedTitlePrefix!: string;
  @PropSync('selectDataSourceFirst', { type: Boolean, default: false }) syncedSelectDataSourceFirst!: boolean;

  @Watch('visible')
  onVisibleChange(visible: boolean) {
    if (visible) {
      this.selectedDataSources = [];
      this.innerRows = null;
      this.reset();
      this.indexB = this.rows.length > 1 ? 1 : 0;
      this.singleSelect = [this.rows[0]];
    }
  }

  view = 'tabular'
  generating = false;
  showDiff = false
  hideSimilarities = false
  truncateHeaders = true
  selectedDataSources: number[] = []
  indexA = 0
  indexB = 0
  panels: Array<number> = []
  selectedColumns: Array<string> = []
  singleSelect: IRow[] = []
  innerRows: IRow[] | null = null;
  pivot = true
  colors = ['bfbfbf', 'e6b8b7', 'b8cce4', 'd8e4bc', 'fff2cc', 'f7d7e9', 'e2f0d9', 'b7dee8', 'd8e4bc', 'e4dfec', 'fde9d9', 'daeef3']

  get title(): string {
    return this.syncedSelectDataSourceFirst
      ? (this.syncedTitlePrefix ? this.syncedTitlePrefix + ' - ' : '') + ('Select data source (' + this.filteredRows.length + ')')
      : this.filteredRows.length > 1
        ? 'Compare data sources (' + this.filteredRows.length + ')'
        : this.currentViewedItem['Name of Data Source']
  }

  get canExport(): boolean {
    return this.filteredRows.length > 0;
  }

  get selectedRows(): any[] {
    return this.selectedDataSources.map(index => this.filteredRows[index])
  }

  get pivotedTabularRows(): Array<any> {
    if (this.isPivoted && this.rows.length > 0) {
      const rows: Array<any> = [];
      const keys = Object.keys(this.rows[0]);
      const nodIndex = keys.findIndex((item: any) => item === 'Name of Data Source');
      keys.splice(nodIndex, 1);

      keys.forEach((row) => {
        if (row.startsWith('___')) {
          return;
        }

        const item: any = {};

        this.headersCompare.forEach((header, headerIdx) => {
          item[header.value] = this.rows[headerIdx][row];
        });

        if (this.isPivoted && this.selectedColumns.length > 0 && !this.selectedColumns.find(item => item === row)) {
          return;
        }

        if (this.isPivoted && this.hideSimilarities) {
          const keys = Object.keys(item);
          let hasDifferences = false;
          for (let i = 0; i < keys.length; i++) {
            const currentValue = item[keys[i]];
            if (item[keys[0]] !== currentValue) {
              hasDifferences = true;
            }
          }
          if (!hasDifferences) {
            return;
          }
        }

        item['Name of Data Source'] = row;
        rows.push(item);
      });
      return rows;
    }

    return this.rows;
  }

  get hasMultipleRows(): boolean {
    return this.filteredRows.length > 1 && this.filteredRows[0] !== this.filteredRows[1];
  }

  get comparedRows(): Array<any> {
    const results: any[] = [];
    if (this.filteredRows[this.indexA]) {
      results.push(this.filteredRows[this.indexA]);
    }
    if (this.filteredRows[this.indexB] && this.hasMultipleRows) {
      results.push(this.filteredRows[this.indexB]);
    }
    return results;
  }

  get filteredRows(): Array<any> {
    return this.innerRows ? this.innerRows : this.rows;
  }

  get currentViewedItem(): {[key: string]: string} {
    return this.filteredRows[this.indexA]
      ? this.filteredRows[this.indexA]
      : {};
  }

  get categories(): Array<ICategory> {
    const results: Array<ICategory> = [];
    this.pivotedHeaders.forEach((header, headerIdx: number) => {
      let category = results.find(item => item.text === header.category)
      if (!category) {
        category = {
          text: header.category,
          headers: [],
        };
        results.push(category);
      }
      category.headers.push({
        index: headerIdx,
        data: header,
      })
    })
    return results;
  }

  get isPivoted(): boolean {
    return this.pivot
      && this.view === 'tabular';
  }

  get headersSlot(): Array<any> {
    return this.pivotedHeaders.map(header => ({
      ...header,
      slot: 'item.' + header.value,
    }))
  }

  get headerFilters(): Array<any> {
    const items = [...this.headers].sort((a, b) => a.text > b.text ? 1 : -1);
    return items.filter(item => item.text !== 'Name of Data Source');
  }

  get headersCompare(): Array<any> {
    return this.pivotedHeaders.filter(item => item.text !== 'Name of Data Source');
  }

  get pivotedHeaders(): Array<any> {
    if (this.isPivoted) {
      const key = 'Name of Data Source';
      return this.filteredRows.map(row => ({
        text: row[key],
        value: row[key],
        excerpt: this.truncateHeaders ? this.$options.filters?.excerpt(row[key], 32) : row[key],
        class: 'text-no-wrap',
      }));
    }
    return this.headers;
  }

  getSelectedItemToCompare(row: any, header: any): any {
    return this.isPivoted || this.singleSelect.length === 0
      ? row[this.getComparedHeaders(this.rows)[1].value]
      : this.singleSelect[0][header.value];
  }

  getComparedHeaders(rows: Array<{[key: string]: string}>): Array<any> {

    if (this.isPivoted) {
      const headers = [...this.pivotedHeaders];
      headers.unshift({
        text: 'Field',
        value: 'Name of Data Source',
        class: 'text-no-wrap',
        fixed: true,
        width: 300,
      });
      return headers;
    }

    const headers = this.hideSimilarities
      ? this.pivotedHeaders.filter(header => {

        if (this.selectedColumns.length > 0 && (this.selectedColumns.indexOf(header.text) === -1 || header.text === 'Name of Data Source')) {
          return false;
        }

        let rowsHaveDifference = false;
        for (let i = 1; i < rows.length; i++) {
          const row = rows[i];
          if (row[header.text] !== rows[i - 1][header.text]) {
            rowsHaveDifference = true;
          }
        }
        return rowsHaveDifference;
      })
      : this.pivotedHeaders.filter(header => {
        return !(this.selectedColumns.length > 0 && (this.selectedColumns.indexOf(header.text) === -1 || header.text === 'Name of Data Source'));
      });

    if (!headers.find(header => header.value === 'Name of Data Source')) {
      headers.unshift({
        text: 'Name of Data Source',
        value: 'Name of Data Source',
        class: 'text-no-wrap',
        fixed: true,
        width: 300,
      });
    }

    return headers;
  }

  onExpandAllPanels() {
    this.panels = [];
    for (let i = 0; i < this.categories.length; i++) {
      this.panels.push(i);
    }
  }

  onCollapseAllPanels() {
    this.panels = [];
  }

  onSelectDataSourceClick(rows: IRow[]) {
    this.reset();
    this.syncedTitlePrefix = '';
    this.syncedSelectDataSourceFirst = false;
    this.innerRows = rows;
    this.indexB = rows.length > 1 ? 1 : 0;
  }

  onFlipIndexes() {
    const indexA = this.indexA;
    const indexB = this.indexB;
    this.indexA = indexB;
    this.indexB = indexA;
  }

  onPivotTable() {
    this.pivot = !this.pivot;
    this.selectedColumns = [];
  }

  onGenerating(value: any) {
    this.generating = value;
  }

  reset() {
    this.view = 'tabular'
    this.generating = false;
    this.showDiff = false
    this.hideSimilarities = false
    this.truncateHeaders = true
    this.pivot = true
    this.indexA = 0;
    this.indexB = 0;
    this.singleSelect = [];
    this.panels = [];
  }
}
</script>

<style lang="scss" scoped>
#metadata_content {
  position: absolute;
  opacity: 0.01;
  max-height: 100px;
  overflow: auto;
}
.dt-compare ::v-deep .v-data-table__wrapper {
  max-height: calc(100vh - 340px);
}
</style>
