<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'trackers'"
      title="Trackers"
      :headers="headers"
      :service="service"
      :hide-new-button="hideNewButton"
      index="tracker"
      fill-height
      hide-collapsable
    />
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import TrackerService from '@/services/tracker.service';
import Identity from '@/modules/sdk/core/identity';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('Admin/List/TrackerList');

@Component({
  components: {
    ListView,
    DataTable
  }
})
export default class TrackerList extends Vue {

  service = TrackerService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'TrackerForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Tracker',
      align: 'start',
      sortable: true,
      value: 'label',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
        operator: 'contains',
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date',
        operator: 'starts with',
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];

  get hideNewButton(): boolean {
    return !Identity.hasRole(['admin']);
  }
}
</script>
