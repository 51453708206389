<template>
  <v-container class="pa-4 h-100" fluid>

    <portal v-if="showPortalOptions" to="toolbar_right" :order="2">
      <MenuTooltip
        :menu-attrs="{ bottom: true, left: true,  closeOnContentClick: false }"
        :btn-attrs="{ text: true }"
        btn-text="Context"
        chevron-down
      >
        <v-card style="width: 20rem">
          <v-card-text>
            <v-checkbox
              v-model="keepViewState"
              label="Keep view state in memory"
              class="ma-0 pa-0"
              hide-details
            />
          </v-card-text>
        </v-card>
      </MenuTooltip>
    </portal>

    <v-form v-model="formIsValid" :disabled="searching" class="d-flex flex-column h-100" @submit.prevent="onSubmitForm">

      <!-- QUERY INSPECTOR DIALOG -->
      <TrackerViewQueryInspectorDialog
        v-model="queryInspectorDialog.visible"
        :column-index="queryInspectorDialog.columnIdx"
        :tracker.sync="queryInspectorDialog.tracker"
        :disabled.sync="queryInspectorDialog.disabled"
        :saving.sync="queryInspectorDialog.saving"
        max-width="1400"
        background-color="background"
        scrollable
        @save="() => onSubmitForm()"
      />

      <!-- COMPARE DIALOG -->
      <TrackerViewCompareDialog
        v-model="compareDialog.visible"
        :rows="compareDialog.rows"
        :headers="parsedHeaders"
        :definitions="definitions"
        :abbreviations="abbreviations"
        :tracker="selectedTracker"
        :title-prefix.sync="compareDialog.titlePrefix"
        :select-data-source-first.sync="compareDialog.selectDataSourceFirst"
      />

      <!-- FILE SELECTOR -->
      <v-card style="flex: 0">
        <v-card-title class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-icon left>mdi-database</v-icon>
            <span>Project Selector</span>
          </div>
          <TrackerAutocomplete
            v-model="selectedTracker"
            :items.sync="trackerList"
            label="Select"
            ref="trackerAutocomplete"
            outlined
            dense
            hide-details
            show-download
            return-object
            clearable
            style="max-width: 20rem"
            @change="onChangeTracker"
            @load="onLoadTrackers"
            @parse="onParseTrackers"
          />
        </v-card-title>
      </v-card>

      <!-- QUERY BUILDER -->
      <div class="d-flex flex-column" style="flex: 0">
        <v-card v-if="selectedTracker" class="mt-3">

          <!-- HEADER -->
          <v-card-title>
            <div class="d-flex align-center flex-wrap justify-space-between w-100" style="gap: 1rem">
              <div class="text-truncate">
                <v-icon left>mdi-magnify</v-icon>
                <span>Advanced Search Builder</span>
              </div>
              <div class="d-flex align-center" style="gap: 0.5rem">
                <PresetManager
                  ref="presetManager"
                  :value="filtersTmp"
                  :default-item="[defaultFilterItem]"
                  :loading="loadingPresets"
                  :saving="savingPreset"
                  :default-item-args="{
                    trackerId: selectedTracker?.data.id,
                  }"
                  :load-callback="loadPresets"
                  :save-callback="savePresets"
                  :remove-callback="removePreset"
                  id="tracker_filters"
                  label="Presets"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                  style="max-width: 35rem"
                  class="mr-4"
                  @input="onApplyPresetFilters"
                />
                <v-btn type="submit" :disabled="!canSearch" :loading="searching" color="primary" class="px-4">
                  <span v-text="$t('btn.search')"></span>
                </v-btn>
                <v-btn text :disabled="!canReset" @click="onResetClick">
                  <span v-text="$t('btn.reset')"></span>
                </v-btn>
              </div>
            </div>
          </v-card-title>

          <!-- CONTENT -->
          <v-card-text>
            <TrackerQueryBuilder
              v-model="filtersTmp"
              :filter-list="filterList"
              :studies="selectedTracker.data.heatmap.data.columns"
              :legends="legends"
            />
          </v-card-text>
        </v-card>
      </div>

      <!-- CONTENT -->
      <div class="d-flex flex-column" style="flex: 1">
        <v-card v-if="selectedTracker" class="mt-3 d-flex flex-column" style="flex: 1">

          <!-- TABS -->
          <v-tabs v-model="tab" color="primary" style="flex: 0" show-arrows grow>
            <v-tab>
              <v-icon left>mdi-format-list-bulleted-square</v-icon>
              <span>List</span>
              <v-badge
                v-if="filteredRows.length > 0"
                :content="filteredRows.length"
                inline
                tile
                class="ml-2"
              />
            </v-tab>
            <v-tab>
              <v-icon left>mdi-table</v-icon>
              <span>Data Table</span>
            </v-tab>
            <v-tab :disabled="loadingDashboards">
              <v-progress-circular v-if="loadingDashboards" indeterminate size="16" width="2" class="mr-3" />
              <v-icon v-else left>mdi-chart-bar-stacked</v-icon>
              <span>Visualization</span>
            </v-tab>
            <v-tab>
              <v-icon left>mdi-map</v-icon>
              <span>Map</span>
            </v-tab>
            <v-tab :disabled="selectedTracker.data.heatmap.data.columns.length === 0">
              <v-icon left>mdi-coolant-temperature</v-icon>
              <span>Heatmap</span>
            </v-tab>
          </v-tabs>

          <!-- TABS: CONTENT -->
          <v-tabs-items
            v-model="tab"
            :style="{
              flex: 1,
              minHeight: tabMinHeight + 'px',
            }"
            ref="tabs"
            class="h-100 flex-inner-100"
          >

            <!-- LIST -->
            <v-tab-item transition="none">
              <TrackerViewList
                v-if="tabName === 'list'"
                v-model="selectedTracker"
                :loading="showLoading"
                :rows="filteredRows"
                :headers="parsedHeaders"
                :definitions="definitions"
                class="h-100"
                @click:item="onItemClick"
              />
            </v-tab-item>

            <!-- DATA TABLE -->
            <v-tab-item transition="none">
              <TrackerViewDatatable
                v-if="tabName === 'datatable'"
                v-model="selectedTracker"
                :loading="showLoading"
                :rows="filteredRows"
                :headers="parsedHeaders"
                :definitions="definitions"
                :abbreviations="abbreviations"
                :height="beforeTabHeight"
                style="flex: 1"
                ref="datatable"
                @click:item="onItemClick"
              />
            </v-tab-item>

            <!-- VISUALIZATION -->
            <v-tab-item transition="none">
              <TrackerViewVisualization
                v-model="selectedTracker"
                :loading="showLoading"
                :rows="filteredRows"
                :headers="parsedHeaders"
                :filters="parsedFilters"
                :definitions="definitions"
                ref="visualization"
                class="h-100 d-flex flex-column"
                @loading="onLoadingDashboard"
                @click:item="onItemClick"
              />
            </v-tab-item>

            <!-- MAP -->
            <v-tab-item transition="none">
              <TrackerViewMap
                :loading="showLoading"
                :rows="filteredRows"
                class="h-100"
                @click:item="onItemClick"
              />
            </v-tab-item>

            <!-- HEATMAP -->
            <v-tab-item transition="none">
              <TrackerViewHeatmap
                v-if="tabName === 'heatmap'"
                v-model="selectedTracker"
                :loading="showLoading"
                :rows="filteredRows"
                :legends="legends"
                :definitions="definitions"
                :height="beforeTabHeight"
                ref="heatmap"
                class="h-100 d-flex flex-column"
                style="flex: 1"
                @click:item="onItemClick"
                @click:inspect="onInspectClick"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Ref, Watch} from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import MenuTooltip from '@/modules/common/components/MenuTooltip.vue';
import { defaultQueryBuilderItem } from '@/modules/common/components/QueryBuilder.vue';
import TrackerAutocomplete from '@/components/TrackerAutocomplete.vue';
import PresetManager from '@/modules/common/components/PresetManager.vue';
import TrackerModel from '@/models/tracker.model';
import TrackerFilterStateService from '@/services/tracker-filter-state.service';
import Identity from '@/modules/sdk/core/identity';
import Query, { IFilter, IPresetFilter, IQueryItem } from '@/modules/sdk/core/query';
import TrackerQueryBuilder from '@/components/TrackerQueryBuilder.vue';
import {IDataHeader, IDefinition, ILegend} from '@/interfaces';
import TrackerViewList from '@/views/Admin/TrackerView/TrackerViewList.vue';
import TrackerViewQueryInspectorDialog from '@/views/Admin/TrackerView/TrackerViewQueryInspectorDialog.vue';
import TrackerViewCompareDialog from '@/views/Admin/TrackerView/TrackerViewCompareDialog.vue';
import TrackerViewDatatable from '@/views/Admin/TrackerView/TrackerViewDatatable.vue';
import TrackerViewVisualization from '@/views/Admin/TrackerView/TrackerViewVisualization.vue';
import TrackerViewMap from '@/views/Admin/TrackerView/TrackerViewMap.vue';
import TrackerViewHeatmap from '@/views/Admin/TrackerView/TrackerViewHeatmap.vue';
import {getFilteredHeatmapRows, getLegends} from '@/heatmap';
import AbbreviationService from '@/services/abbreviation.service';
import AbbreviationModel from '@/models/abbreviation.model';

@Component({
  components: {
    TrackerViewHeatmap,
    TrackerViewVisualization,
    TrackerViewDatatable,
    TrackerViewCompareDialog,
    TrackerViewQueryInspectorDialog,
    TrackerViewList,
    TrackerQueryBuilder,
    TrackerViewMap,
    ModalDialog,
    PresetManager,
    TrackerAutocomplete,
    MenuTooltip,
  }
})
export default class TrackerView extends Vue {
  @Ref() readonly presetManager!: PresetManager;
  @Ref() readonly trackerAutocomplete!: TrackerAutocomplete;
  @Ref() readonly datatable!: TrackerViewDatatable;
  @Ref() readonly visualization!: TrackerViewVisualization;
  @Ref() readonly heatmap!: TrackerViewHeatmap;

  tab = 0
  tabs = ['list', 'datatable', 'visualization', 'map', 'heatmap']
  beforeTabHeight = 400;
  loadingDashboards = false;
  loadingPresets = false;
  loadingAbbreviation = false
  savingPreset = false;
  parsed = false;
  formIsValid = false
  loading = false
  searching = false
  keepViewState = false
  truncateHeaders = true
  legends: Array<ILegend> = []
  selectedTracker: TrackerModel | null = null;
  trackerList: Array<TrackerModel> = [];
  filters: Array<IQueryItem> = []
  filtersTmp: Array<IQueryItem> = []
  presetFilter: IPresetFilter | null = null;
  filterList: Array<IFilter> = []
  originalFilterList: Array<IFilter> = []
  headers: Array<IDataHeader> = []
  definitions: Array<IDefinition> = []
  abbreviations: Array<AbbreviationModel> = []
  rows: Array<{[key: string]: string}> = []
  defaultFilterItem: IQueryItem = {
    ...defaultQueryBuilderItem,
  }

  queryInspectorDialog: {
    visible: boolean,
    disabled: boolean,
    saving: boolean,
    columnIdx: number,
    tracker: TrackerModel,
  } = {
    visible: false,
    disabled: true,
    saving: false,
    columnIdx: -1,
    tracker: new TrackerModel(),
  }

  compareDialog: {
    visible: boolean,
    selectDataSourceFirst: boolean,
    rows: Array<{[key: string]: string}>,
    titlePrefix: string | null,
  } = {
    visible: false,
    selectDataSourceFirst: false,
    rows: [],
    titlePrefix: null,
  }

  @Watch('tab', { immediate: true })
  onFiltersTmpChange() {
    setTimeout(() => {
      if (this.tabName === 'datatable' && this.datatable) {
        this.datatable.updateEvents();
      } else if (this.tabName === 'visualization' && this.visualization && !this.visualization.loaded && !this.visualization.loadingDashboards) {
        this.visualization.loadDashboards(this.visualization.tracker);
      } else if (this.tabName === 'visualization') {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        })
      }
    })
  }

  @Watch('tab')
  @Watch('filtersTmp', { deep: true })
  @Watch('selectedTracker', { deep: true })
  onSelectedTrackerChange() {
    this.saveViewState();
    setTimeout(() => {
      this.updateBeforeTabHeight();
    }, 50);
    this.updateBeforeTabHeight();
  }

  @Watch('keepViewState')
  onKeepViewStateChanged(state: boolean) {
    localStorage.setItem('tracker_keep_view_state', JSON.stringify(state));
  }

  get tabName(): string {
    return this.tabs[this.tab];
  }

  get showPortalOptions(): boolean {
    return Identity.hasRole(['admin', 'dev']);
  }

  get showLoading(): boolean {
    return this.searching || this.loading || !this.parsed;
  }

  get parsedHeaders(): Array<any> {
    return this.headers.map(header => {
      const values: any = {
        text: header.text,
        value: header.text,
        excerpt: this.truncateHeaders ? this.$options.filters?.excerpt(header.text, 32) : header.text,
        category: header.category,
        class: 'text-no-wrap',
      };
      switch (header.text) {
        case 'Name of Data Source':
          values.fixed = true;
          values.width = 300;
          break;
        case 'Name of Linkable Data Source':
        case 'Details regarding Data Access(if available)':
        case 'Comments':
          values.width = 900;
          break;
      }
      return values;
    });
  }

  get canSearch(): boolean {
    return !this.searching && !this.loading &&
      JSON.stringify(this.filtersTmp) !== JSON.stringify(this.filters) &&
      this.formIsValid;
  }

  get canReset(): boolean {
    return !this.searching && !this.loading &&
      JSON.stringify(this.filtersTmp) !== JSON.stringify([this.defaultFilterItem]);
  }

  get parsedFilters(): Array<IQueryItem> {
    return this.adjustFilters(this.filters);
  }

  get filteredRows(): Array<{[key: string]: string}> {
    const filters = this.adjustFilters(this.filters);
    return Query.filterItems(filters, this.rows, false);
  }

  get tabMinHeight(): number {
    return window.innerHeight - 500;
  }

  getBeforeTabHeight(): number {
    const minHeight = this.tabMinHeight;
    let height = minHeight;
    if (this.$refs.tabs) {
      const boundingClientRect = (this.$refs.tabs as any).$el.getBoundingClientRect();
      height = boundingClientRect.top;
    }

    height = window.innerHeight - height;
    if (height < minHeight) {
      height = minHeight;
    }

    return height - 60;
  }

  onChangeTracker() {
    if (this.parsed) {
      this.reset();
      this.parsed = false;
      this.loading = true;
    }
  }

  onApplyPresetFilters(preset: any) {
    this.filtersTmp = preset;
    this.onSubmitForm();
  }

  onSubmitForm() {
    this.filters = structuredClone(this.filtersTmp);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    })
  }

  onResetClick() {
    this.resetFilters();

    if (this.presetManager) {
      this.presetManager.reset();
    }

    this.onSubmitForm();
  }

  onLoadingDashboard(value: boolean) {
    this.loadingDashboards = value;
  }

  onItemClick(props: {
    rows: Array<{[key: string]: string}>[],
    titlePrefix: string,
    selectDataSourceFirst: boolean,
  }) {
    Object.assign(this.compareDialog, {
      visible: true,
      selectDataSourceFirst: false,
      titlePrefix: '',
    }, props)
  }

  onInspectClick(props: {
    disabled: boolean,
    index: number,
    tracker: TrackerModel,
  }) {
    Object.assign(this.queryInspectorDialog, {
      visible: true,
      ...props,
    })
  }

  onLoadTrackers() {
    this.keepViewState = JSON.parse(localStorage.getItem('tracker_keep_view_state') || 'false') || false;
    if (this.keepViewState) {
      this.loadViewState();
    } else if (this.trackerAutocomplete && this.trackerList.length > 0) {
      this.selectedTracker = this.trackerList[0];
      this.trackerAutocomplete.onChange(this.trackerList[0]);
    }
  }

  onParseTrackers({ headers, rows, filters, definitions }: any) {
    this.headers = headers;
    if (this.selectedTracker) {
      this.rows = getFilteredHeatmapRows(this.selectedTracker, rows);
      this.legends = getLegends(this.selectedTracker, rows);
    }
    this.definitions = definitions;
    this.filterList = filters;
    this.originalFilterList = structuredClone(filters);
    this.loadPresets();
    this.onSubmitForm();
    this.parsed = true;
    this.loading = false;
  }

  adjustFilters(filters: Array<IQueryItem>): Array<IQueryItem> {
    function adjust(filters: Array<IQueryItem>) {
      const items: Array<IQueryItem> = []
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        filter.group = adjust(filter.group);
        if (filter.category === 'study' && filter.value && filter.value.length > 0) {
          filter.value = (filter.value || []).map((item: any) => item && item.color);
          items.push(filter);
        } else if (filter.category !== 'study' && filter.value && filter.value.length > 0) {
          items.push(filter);
        } else if (filter.group.length > 0) {
          items.push(filter);
        }
      }
      return items;
    }
    return adjust(structuredClone(filters));
  }

  resetFilters() {
    this.presetFilter = null;
    this.filtersTmp.splice(0, this.filtersTmp.length, structuredClone(this.defaultFilterItem));
    this.filterList = structuredClone(this.originalFilterList);
    this.filters = structuredClone(this.filtersTmp);

    if (this.presetManager) {
      this.presetManager.reset();
    }
  }

  loadPresets(): Promise<any> {
    return TrackerFilterStateService.getInstance().getAll({
      filters: [{
        field: 'trackerId',
        operator: 'equals',
        value: this.selectedTracker?.data.id,
      }, [{
        field: 'userId',
        operator: 'is null',
      }, {
        field: 'userId',
        operator: 'equals',
        value: Identity.getIdentity()?.user.id,
      }]]
    }).then(response => response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason));
  }

  savePresets(item: any) {
    return TrackerFilterStateService.getInstance().save(item)
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  removePreset(item: any) {
    return TrackerFilterStateService.getInstance().delete({
      id: item.data.id,
    })
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  saveViewState(): void {
    if (this.keepViewState && this.parsed) {
      localStorage.setItem('tracker_view_state', JSON.stringify({
        selectedTracker: this.selectedTracker?.data.id,
        tab: this.tab,
        filtersTmp: this.filtersTmp,
      }));
    }
  }

  loadViewState(): void {
    const state = JSON.parse(localStorage.getItem('tracker_view_state') || '{}');

    this.filtersTmp.splice(0, this.filtersTmp.length, ...(structuredClone(state.filtersTmp) || []));

    if (state.selectedTracker) {
      this.selectedTracker = this.trackerList.find(tracker => tracker.data.id === state.selectedTracker) || null;

      if (this.trackerAutocomplete) {
        this.trackerAutocomplete.onChange(this.selectedTracker as TrackerModel);
      }
    }
    this.tab = state.tab ? state.tab : this.tab;
  }

  updateBeforeTabHeight() {
    this.beforeTabHeight = this.getBeforeTabHeight();
  }

  loadAbbreviations() {
    this.loadingAbbreviation = true;
    return AbbreviationService.getInstance().getAll()
      .then(response => this.abbreviations = response.data.view.list)
      .then(response => this.$emit('load', response))
      .finally(() => this.loadingAbbreviation = false);
  }

  reset(): void {
    this.filterList = []
    this.headers = []
    this.definitions = []
    this.rows = []
    this.resetFilters();
  }

  created() {
    this.reset();
    this.loadAbbreviations();
  }

  mounted() {
    window.addEventListener('resize', this.updateBeforeTabHeight);
  }

  destroyed() {
    window.removeEventListener('resize', this.updateBeforeTabHeight);
  }
}
</script>
