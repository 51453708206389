<template>
  <div>
    <ExportOptionsModal
      v-model="exportOptions.visible"
      :headers="exportOptions.headers"
      :is-pivoted="exportOptions.isPivoted"
      :format="exportOptions.format"
      :all-rows="allRows"
      :selected-rows="rows"
      @export="onExport"
    />
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="{ ...$attrs, ...attrs }" v-on="on">
          Export as...
          <v-icon right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="exportAs('pdf')">
          <v-list-item-icon>
            <v-icon>mdi-file-document-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>PDF</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="exportAs('excel')">
          <v-list-item-icon>
            <v-icon>mdi-file-excel-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Excel</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="exportAs('csv')">
          <v-list-item-icon>
            <v-icon>mdi-file-table-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>CSV</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="exportAs('json')">
          <v-list-item-icon>
            <v-icon>mdi-code-braces</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>JSON</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import { json2csv } from 'json-2-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import ExcelJS from 'exceljs'
import {IDefinition} from '@/interfaces';
import Utils from '@/modules/sdk/core/utils';
import ExportOptionsModal, { IExportOptions } from '@/components/ExportOptionsModal.vue';

export interface IExportToUnclassified {
  label: string,
  value: string,
  values: string[],
}

export interface IExportToCategory {
  label: string,
  unclassified: IExportToUnclassified[],
  children: Array<{ [key: string]: string }>,
}

export interface IExportToItem {
  label: string,
  categories: Array<IExportToCategory>
}

export interface IExportToData {
  title: string,
  items: Array<IExportToItem>,
}

@Component({
  components: {
    ExportOptionsModal,
  }
})
export default class ExportRowsMenu extends Vue {
  @VModel({ type: Array, default: () => ([]) }) rows!: {[key: string]: string}[]
  @Prop({ type: Array, default: null }) allRows!: {[key: string]: string}[]
  @Prop({ type: Array, default: null }) pivotedRows!: {[key: string]: string}[]
  @Prop({ type: String, default: 'Untitled' }) title!: string
  @Prop({ type: Array, default: () => ([]) }) definitions!: IDefinition[]
  @Prop({ type: Array, default: () => ([]) }) headers!: any[]
  @Prop({ type: Array, default: null }) pivotedHeaders!: any[]

  onExport(data: IExportOptions) {
    switch (data.format) {
      case 'pdf': this.downloadAsPDF(data); break;
      case 'excel': this.downloadAsExcel(data); break;
      case 'csv': this.downloadAsCSV(data); break;
      case 'json': this.downloadAsJSON(data); break;
    }
  }

  exportOptions: {
    visible: boolean,
    format: string,
    headers: any[],
    isPivoted: boolean,
  } = {
    visible: false,
    format: 'csv',
    headers: [],
    isPivoted: false,
  }

  get dynamicRows(): {[key: string]: string}[] {
    return this.pivotedRows || this.rows;
  }

  get dynamicHeaders(): {[key: string]: string}[] {
    return this.pivotedHeaders || this.headers;
  }

  exportAs(format: string) {
    Object.assign(this.exportOptions, {
      visible: true,
      headers: this.headers, // this.dynamicHeaders,
      isPivoted: this.pivotedHeaders !== null,
      format,
    })
  }

  downloadAsPDF(data: IExportOptions) {
    this.$emit('generating', 'PDF');
    setTimeout(() => {
      const exportData = this.getExportToData(data, this.title);
      const doc = new jsPDF();
      const initialY = 20;
      const maxY = 270;
      const endX = 196;
      let startY = initialY;

      function printBranding() {
        const img = new Image();
        const factor = 3;
        img.src = '/images/branding.png';
        doc.addImage(img, 'png', 14, startY - 10, 158 / factor, 50 / factor);
        startY += (50 / factor);
      }

      function drawLine() {
        doc.setLineWidth(0.2);
        doc.setDrawColor(224, 224, 224);
        doc.line(14, startY, endX, startY);
        startY += 10;
      }

      function addFooters() {
        const totalPages = doc.getNumberOfPages();
        for (let i = 0; i < totalPages; i++) {
          startY = maxY;
          doc.setPage(i + 1);
          drawLine();
          printText(
            'Copyright © 2023-' + new Date().getFullYear() + ' YolaRx Consultant Inc.',
            9,
            'normal',
            '#999999',
          );
          printText('All rights reserved.', 9, 'normal', '#999999');
          startY = maxY + 10;
          doc.text('Page ' + (i + 1) + ' or ' + totalPages, endX, startY, {
            align: 'right',
          });
        }
      }

      function printText(str: string, size = 12, style = 'normal', color = '#000000') {
        doc.setFontSize(size);
        doc.setFont('Courier', style);
        doc.setTextColor(color);

        const splitTitle = doc.splitTextToSize(str, endX);
        doc.text(splitTitle, 14, startY);
        startY += ((size * splitTitle.length) / 2.5);
      }

      // Print branding at top of the page
      printBranding();

      // Main title
      printText(this.title, 24, 'bold');
      startY += 10;

      exportData.items.forEach((item, itemIdx) => {

        // Print category label if more than one item
        if (exportData.items.length > 1) {
          if (itemIdx > 0) {
            doc.addPage();
            startY = initialY;
          }
          printText(item.label || 'Untitled', 18, 'bold', '#0073cf');
          drawLine();
        }

        item.categories.forEach(category => {
          const rows: Array<{ label: string, value: string }> = [];
          category.unclassified.forEach(row => {
            if (row.label !== 'Name of Data Source') {
              rows.push({
                label: row.label,
                value: Utils.richText(row.values.length <= 1
                  ? row.values[0]
                  : ('• ' + row.values.join('\n• ')) || '-', false),
              });
            }
          })

          // Print category label
          if (startY >= maxY) {
            doc.addPage();
            startY = initialY;
          }
          printText(category.label || 'Uncategorized', 15, 'bold');

          // Print table
          autoTable(doc, {
            showHead: 'never',
            tableWidth: 'wrap',
            startY,
            margin: { bottom: 30 },
            columnStyles: {
              label: { cellWidth: 100, fontStyle: 'bold' },
              value: { cellWidth: 82 },
            },
            columns: [
              { header: 'Label', dataKey: 'label' },
              { header: 'Value', dataKey: 'value' },
            ],
            body: rows,
          })

          // @ts-ignore
          startY = doc.lastAutoTable.finalY + 15;
        })
      })

      addFooters();

      const nameOfDataSource = this.title || 'Untitled';
      doc.save(nameOfDataSource + '.pdf');

      this.$emit('generating', false);
    })
  }

  downloadAsExcel(data: IExportOptions) {
    this.$emit('generating', 'Excel');
    setTimeout(() => {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = 'GlosaRx';
      workbook.created = new Date();

      const sheet = workbook.addWorksheet(this.title);
      const columns: any[] = [];
      data.headers.forEach(header => {
        columns.push({
          name: header.text,
        });
      })

      sheet.addTable({
        name: 'DataSources',
        ref: 'A1',
        columns,
        rows: data.rows.map(row => columns.map(column => row[column.name === 'Field' ? 'Name of Data Source' : column.name])),
      });

      workbook.xlsx.writeBuffer().then(buffer => {
        // @ts-ignore
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.title + '.xlsx';
        link.click();
        link.remove();
        this.$emit('generating', false);
      });
    })
  }

  downloadAsCSV(data: IExportOptions) {
    this.$emit('generating', 'CSV');
    setTimeout(() => {
      const csv = json2csv([
        data.headers.map(header => header.text),
        ...data.rows.map(row => this.dynamicHeaders.map(header => row[header.text === 'Field' ? 'Name of Data Source' : header.text])),
      ], {
        arrayIndexesAsKeys: true,
        expandNestedObjects: true,
        prependHeader: false,
      });

      const base64 = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      const link = document.createElement('a');
      link.setAttribute('href', base64);
      link.setAttribute('download', this.title + '.csv');
      link.click();
      link.remove();
    })
    this.$emit('generating', false);
  }

  downloadAsJSON(data: IExportOptions) {
    this.$emit('generating', 'JSON');
    setTimeout(() => {
      const structuredData = data.rows.map(row => {
        const result: {[key: string]: string} = {};
        data.headers.forEach(header => {
          const key = header.text === 'Field' ? 'Name of Data Source' : header.text;
          result[header.text] = row[key];
        })
        return result;
      });
      const json = JSON.stringify(structuredData);
      const base64 = 'data:text/json;charset=utf-8,' + encodeURIComponent(json);
      const link = document.createElement('a');
      link.setAttribute('href', base64);
      link.setAttribute('download', this.title + '.json');
      link.click();
      link.remove();
    })
    this.$emit('generating', false);
  }

  getExportToData(data: IExportOptions, title: string): IExportToData {
    const newItems: Array<IExportToItem> = [];
    data.rows.forEach(item => {
      newItems.push({
        label: item['Name of Data Source'],
        categories: this.getGroupedData(item, data.headers),
      });
    })

    return {
      title,
      items: newItems,
    }
  }

  getGroupedData(row: any, headers: any[]) {
    const currentKeys = headers.map(header => header.value);
    const results: Array<any> = [];
    headers.forEach((header, headerIdx: number) => {
      let newCategory = results.find(item => item.label === header.category);
      if (!newCategory) {
        newCategory = {
          label: header.category,
          keys: [],
          children: [],
          unclassified: [],
        };

        const subCategories = [...new Set(this.definitions.filter(definition => definition.subCategory && definition.category === header.category))];
        subCategories.forEach(subCategory => {
          let newSubCategory = newCategory.children.find((item: any) => subCategory.subCategory && item.label === subCategory.subCategory);
          if (!newSubCategory) {
            newSubCategory = {
              key: subCategory.name,
              label: subCategory.subCategory,
              children: [],
            }
            newCategory.children.push(newSubCategory);
          }
          const keys = currentKeys.filter(key => key === subCategory.name);
          keys.forEach(key => {
            newSubCategory.children.push({
              label: key,
              value: row[key],
            })
          })
        });
        results.push(newCategory);
      }
      newCategory.keys.push(currentKeys[headerIdx]);
    });

    results.forEach(category => {
      const remainingKeys: Array<string> = category.keys.filter((key: string) => {
        return !category.children.find((subCategory: any) =>
            subCategory.key === key || subCategory.children.find((subSubCategory: any) => {
              return subSubCategory.label === key;
            })
        )
      });
      if (remainingKeys.length > 0) {
        remainingKeys.forEach(key => {
          category.unclassified.push({
            label: key,
            value: row[key],
          })
        })
      }
    });

    results.forEach(category => {
      category.unclassified.forEach((item: IExportToUnclassified) => {
        const definition: any = this.definitions.find(definition => definition.name === item.label) || { single: true };
        const value = (item.value || '').toString();
        item.values = definition.single
          ? [value]
          : value.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g) || [];
        item.values = item.values.map(value => {
          return value.trim().substring(
            value.trim().startsWith('"') ? 1 : 0,
            value.trim().endsWith('"') ? value.length - 1 : value.length,
          );
        });
      });
    });

    return results;
  }
}
</script>
