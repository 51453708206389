<template>
  <v-container class="pa-4" fluid>
    <h3 class="mt-6">IFrame Communicator</h3>
<!--    <IFrameCommunicator-->
<!--      v-model="iframe"-->
<!--      src="https://localhost:8080/"-->
<!--      style="height: 30rem"-->
<!--      class="w-100"-->
<!--      @data="onData"-->
<!--    />-->
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import IFrameCommunicator from '@/modules/common/components/IFrameCommunicator.vue';

type IMessageContent = { [key: string]: any }

@Component({
  components: {
    IFrameCommunicator,
  }
})
export default class DevView extends Vue {

  iframe: IMessageContent = {
    test: 123,
    more: 456,
  }

  onData(props: IMessageContent) {
    console.log('data', props);
  }
}
</script>
