import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';

import ErrorView from '@/modules/common/views/ErrorView.vue';

import Login from '@/modules/common/views/auth/Login.vue';
import ForgotPassword from '@/modules/common/views/auth/ForgotPassword.vue';
import ResetPassword from '@/modules/common/views/auth/ResetPassword.vue';
import HomeView from '@/views/Admin/HomeView.vue';
import SubView from '@/modules/common/views/SubView.vue';

import MyProfileView from '@/modules/common/views/MyProfileView.vue';
import DevView from '@/views/Admin/DevView.vue';
import TrackerView from '@/views/Admin/TrackerView.vue';

import AbbreviationList from '@/views/Admin/List/AbbreviationList.vue';
import AbbreviationForm from '@/views/Admin/Form/AbbreviationForm.vue';

import RoleList from '@/modules/common/views/list/RoleList.vue';
import RoleForm from '@/modules/common/views/form/RoleForm.vue';

import TemplateList from '@/modules/common/views/list/TemplateList.vue';
import TemplateForm from '@/modules/common/views/form/TemplateForm.vue';

import UserList from '@/modules/common/views/list/UserList.vue';
import UserForm from '@/modules/common/views/form/UserForm.vue';

import VideoLibraryList from '@/modules/common/views/list/VideoLibraryList.vue';
import VideoLibraryForm from '@/modules/common/views/form/VideoLibraryForm.vue';

import TrackerList from '@/views/Admin/List/TrackerList.vue';
import TrackerForm from '@/views/Admin/Form/TrackerForm.vue';

import CountryList from '@/modules/common/views/list/CountryList.vue';
import CountryForm from '@/modules/common/views/form/CountryForm.vue';

import ContactForm from '@/modules/common/views/form/ContactForm.vue';
import ContactList from '@/modules/common/views/list/ContactList.vue';

import SatisfactionForm from '@/modules/common/views/form/SatisfactionForm.vue';
import SatisfactionList from '@/modules/common/views/list/SatisfactionList.vue';

import NotificationForm from '@/modules/common/views/form/NotificationForm.vue';
import NotificationList from '@/modules/common/views/list/NotificationList.vue';

import TutorialView from '@/modules/common/views/TutorialView.vue';

import authGuard from '@/router/guards/auth.guard';
import appGuard from '@/router/guards/app.guard';
import roleGuard from '@/router/guards/role.guard';
import { NavigationGuardNext } from 'vue-router/types/router';
import Identity from '@/modules/sdk/core/identity';

const devGuard = roleGuard('dev');
const adminGuard = roleGuard(['dev', 'admin']);
const trackerGuard = roleGuard(['dev', 'admin', 'tracker']);
const leaderGuard = roleGuard(['dev', 'admin', 'leader']);
const researcherGuard = roleGuard(['dev', 'admin', 'leader', 'arbitrator', 'researcher']);
const clientGuard = roleGuard(['dev', 'admin', 'leader', 'arbitrator', 'researcher', 'tracker', 'client']);

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // Errors
  { path: '*', redirect: '/error/not-found' },
  { path: '/error/:type?', name: 'error', component: ErrorView, beforeEnter: appGuard },

  // Public
  { path: '/login', name: 'login', component: Login, beforeEnter: authGuard },
  { path: '/forgot-password', name: 'forgotPassword', component: ForgotPassword, beforeEnter: authGuard },
  { path: '/reset-password/:token', name: 'resetPassword', component: ResetPassword, beforeEnter: authGuard },

  // Private
  { path: '/', name: 'HomeView', redirect: { name: 'TrackerView' }, beforeEnter: authGuard },
  // { path: '/', name: 'HomeView', component: HomeView, beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
  //     if (Identity.isLoggedIn() && Identity.hasRole(['client'])) {
  //       return next({
  //         name: 'TrackerView',
  //       });
  //     }
  //     appGuard(to, from, next);
  // } },
  { path: '/my-profile', name: 'MyProfileView', component: MyProfileView, beforeEnter: appGuard },
  { path: '/tracker-view', name: 'TrackerView', component: TrackerView, beforeEnter: clientGuard },

  // List Views
  { path: '/video-library', name: 'VideoLibrary', component: SubView, redirect: { name: 'VideoLibraryList' }, beforeEnter: adminGuard, children: [
      { path: '', name: 'VideoLibraryList', component: VideoLibraryList },
      { path: 'new', name: 'VideoLibraryNew', component: VideoLibraryForm },
      { path: ':id', name: 'VideoLibraryForm', component: VideoLibraryForm },
  ] },
  { path: '/account', name: 'MyAccount', component: UserForm },
  { path: '/user', name: 'User', component: SubView, redirect: { name: 'UserList' }, beforeEnter: adminGuard, children: [
      { path: '', name: 'UserList', component: UserList },
      { path: 'new', name: 'UserNew', component: UserForm },
      { path: ':id', name: 'UserForm', component: UserForm },
  ] },
  { path: '/abbreviation', name: 'Abbreviation', component: SubView, redirect: { name: 'AbbreviationList' }, beforeEnter: adminGuard, children: [
      { path: '', name: 'AbbreviationList', component: AbbreviationList },
      { path: 'new', name: 'AbbreviationNew', component: AbbreviationForm },
      { path: ':id', name: 'AbbreviationForm', component: AbbreviationForm },
  ] },
  { path: '/role', name: 'Role', component: SubView, redirect: { name: 'RoleList' }, beforeEnter: devGuard, children: [
      { path: '', name: 'RoleList', component: RoleList },
      { path: 'new', name: 'RoleNew', component: RoleForm },
      { path: ':id', name: 'RoleForm', component: RoleForm },
  ] },
  { path: '/template', name: 'Template', component: SubView, redirect: { name: 'TemplateList' }, beforeEnter: devGuard, children: [
      { path: '', name: 'TemplateList', component: TemplateList },
      { path: 'new', name: 'TemplateNew', component: TemplateForm },
      { path: ':id', name: 'TemplateForm', component: TemplateForm },
  ] },
  { path: '/notification', name: 'Notification', component: SubView, redirect: { name: 'NotificationList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'NotificationList', component: NotificationList },
    { path: 'new', name: 'NotificationNew', component: NotificationForm },
    { path: ':id?', name: 'NotificationForm', component: NotificationForm },
  ] },
  { path: '/country', name: 'Country', component: SubView, redirect: { name: 'CountryList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'CountryList', component: CountryList },
    { path: 'new', name: 'CountryNew', component: CountryForm },
    { path: ':id?', name: 'CountryForm', component: CountryForm },
  ] },
  { path: '/tracker', name: 'Tracker', component: SubView, redirect: { name: 'TrackerList' }, beforeEnter: trackerGuard, children: [
    { path: '', name: 'TrackerList', component: TrackerList },
    { path: 'new', name: 'TrackerNew', component: TrackerForm },
    { path: ':id?', name: 'TrackerForm', component: TrackerForm },
  ] },
  { path: '/contact', name: 'Contact', component: SubView, redirect: { name: 'ContactList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'ContactList', component: ContactList },
    { path: 'new', name: 'ContactNew', component: ContactForm },
    { path: ':id?', name: 'ContactForm', component: ContactForm },
  ] },
  { path: '/satisfaction', name: 'Satisfaction', component: SubView, redirect: { name: 'SatisfactionList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'SatisfactionList', component: SatisfactionList },
    { path: 'new', name: 'SatisfactionNew', component: SatisfactionForm },
    { path: ':id?', name: 'SatisfactionForm', component: SatisfactionForm },
  ] },

  // Help
  { path: '/help', name: 'Help', component: SubView, redirect: { name: 'TutorialView' }, children: [
      { path: 'tutorials/:index?', name: 'TutorialView', component: TutorialView },
  ] },

  // Developers
  { path: '/dev', name: 'DevView', component: DevView, beforeEnter: devGuard },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
