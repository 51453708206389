import Model from '../core/model';
import RoleModel from './role.model';
import GroupModel from './group.model';
import TypeModel from './type.model';

export default class UserModel extends Model {

  default() {
    return {
      rolelist: [],
      grouplist: [],
      typelist: [],
    };
  }

  relatedMap () {
    return {
      rolelist: RoleModel,
      grouplist: GroupModel,
      typelist: TypeModel,
    }
  }

  relatedDeleteMissing() {
    return {
      rolelist: true,
      grouplist: true,
      typelist: true,
    };
  }

  get fullName(): string {
    return this.getFullName();
  }

  get label(): string {
    return this.getLabel();
  }

  getLabel(): string {
    return (this.data.firstName || '') + ' ' + (this.data.lastName || '') + (this.data.email ? (' <' + this.data.email + '>') : '');
  }

  getFullName(): string {
    return (this.data.firstName || '') + ' ' + (this.data.lastName || '');
  }

  getInitials(): string {
    const str = (this.data.firstName || '') + ' ' + (this.data.lastName || '');
    return str.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
  }
}
