import Utils from '@/modules/sdk/core/utils';
import Identity from '@/modules/sdk/core/identity';
import TrackerModel from '@/models/tracker.model';
import Papa from 'papaparse';
import { IDefinition, IDataHeader, IFilter, HeaderType } from '@/interfaces'

export default class Tracker {

  parseCSV(
    csv: any,
    definitions: Array<IDefinition>,
  ): {
    headers: Array<IDataHeader>,
    rows: Array<{[key: string]: string}>,
  } {
    const headers: Array<IDataHeader> = [];
    const rows: Array<{[key: string]: string}> = [];

    csv.data.forEach((row: Array<string>, rowIdx: number) => {
      if (rowIdx === 0) {
        row.forEach((column: string, columnIdx: number) => {
          const text = column === '' ? csv.data[0][columnIdx] : column;
          const definition = definitions.find(definition => definition.name === text);
          const header: IDataHeader = {
            category: definition ? (definition.category || '').toString() : null,
            type: HeaderType.select,
            text: Utils.removeLineBreaks(text),
          };
          headers.push(header);
        });
      } else {
        row.forEach((column: string, columnIdx: number) => {
          const currentIdx = rowIdx - 1;
          const value: boolean | string | null = column.trim();
          if (!rows[currentIdx]) {
            rows[currentIdx] = {};
          }
          rows[currentIdx][headers[columnIdx].text] = value;
        })
      }
    })

    return {
      headers,
      rows,
    }
  }

  parseFilters(
    headers: Array<IDataHeader>,
    rows: Array<{[key: string]: string}>,
    definitions: Array<IDefinition>,
  ): Array<IFilter> {
    const filters: Array<IFilter> = [];

    headers.forEach((header: IDataHeader) => {
      if (header.text.trim() !== '') {
        const formattedLabel = header.text.substring(0, header.text.indexOf('(see') === -1 ? header.text.length : header.text.indexOf('('))
        const filter: IFilter = {
          label: header.text,
          formattedLabel,
          type: 'select',
          items: [],
        };
        if (
          !filters.find(item => item.label === filter.label) &&
          header.text !== 'Comments'
        ) {
          filters.push(filter);
        }
      }
    });

    this.injectDistinctIntoFilters(headers, rows, filters, definitions);
    return filters;
  }

  injectDistinctIntoFilters(
    headers: Array<IDataHeader>,
    rows: Array<{[key: string]: string}>,
    filters: Array<IFilter>,
    definitions: Array<IDefinition>,
  ): void {
    headers.forEach((header: IDataHeader) => {
      const items: Array<string> = [];
      rows.forEach(row => {
        const definition: any = definitions.find(item => item.name === header.text) || { single: true };
        const value = (row[header.text] || '').toString();
        const values = definition.single
          ? [value]
          : value.split(',');

        values.forEach(val => {
          val = val.trim();
          if (val && items.indexOf(val) === -1) {
            items.push(val);
          }
        })
      })
      const filter = filters.find(filter => filter.label === header.text);
      if (filter) {
        filter.items = items.sort();
      }
    })
  }

  parseDefinitionCSV(csv: any): Array<IDefinition> {
    const rows: Array<IDefinition> = [];
    const headers: Array<string> = [];
    csv.data.forEach((row: Array<string>, rowIdx: number) => {
      const newRow: IDefinition = {};
      row.forEach((cell: string, cellIdx: number) => {
        if (rowIdx === 0) {
          headers.push(Utils.toCamelCase(cell));
        } else {
          const header = headers[cellIdx];
          switch (header) {
            case 'single': newRow[header] = cell === 'checked'; break;
            default: newRow[header] = cell === '' ? null : Utils.removeLineBreaks(cell); break;
          }
        }
      });
      if (rowIdx > 0) {
        rows.push(newRow);
      }
    });
    return rows;
  }

  loadFile(tracker: TrackerModel): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!tracker.data.datafileentity || !tracker.data.definitionfileentity) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(null);
      }

      const dataFile = tracker.data.datafileentity?.data || {};
      const dataFilePath = [
        dataFile.category,
        dataFile.id,
        dataFile.path,
      ];
      const definitionFile = tracker.data.definitionfileentity?.data || {};
      const definitionFilePath = [
        definitionFile.category,
        definitionFile.id,
        definitionFile.path,
      ];

      this.parseContent(
        '/file/download/' + dataFilePath.join('/'),
        '/file/download/' + definitionFilePath.join('/'), {
          download: true,
        }, {
          download: true,
        }
      ).then(resolve);
    })
  }

  parseContent(
    fileContent: string | null,
    definitionContent: string | null,
    fileParams: any = {},
    definitionParams: any = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      Papa.parse(fileContent || '', {
        ...fileParams,
        delimiter: ',',
        skipEmptyLines: true,
        downloadRequestHeaders: {
          'X-Authorization': 'Bearer ' + Identity.getIdentity()?.jwt,
        },
        complete: (dataResults: any) => {
          Papa.parse(definitionContent || '', {
            ...definitionParams,
            delimiter: ',',
            skipEmptyLines: true,
            downloadRequestHeaders: {
              'X-Authorization': 'Bearer ' + Identity.getIdentity()?.jwt,
            },
            complete: (definitionResults: any) => {
              const definitions = this.parseDefinitionCSV(definitionResults);
              const { headers, rows } = this.parseCSV(dataResults, definitions);
              const filters = this.parseFilters(headers, rows, definitions);

              resolve({
                definitions,
                headers,
                rows,
                filters,
              })
            }
          });
        }
      });
    })
  }
}
