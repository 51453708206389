export enum HeaderType {
  select,
  text,
  boolean,
  number,
}

export interface IDiff {
  equals?: boolean,
  added?: boolean,
  removed?: boolean,
  value: any,
}

export interface IDataHeader {
  category: string | null,
  type: HeaderType,
  text: string,
  subCategories?: Array<any>,
}

export interface IDefinition {
  [key: string]: string | null | boolean
}

export interface ILegend {
  color: string,
  text: string,
  dark: boolean,
}

export interface ICategory {
  text: string | null,
  headers: Array<{
    data: IDataHeader,
    index: number
  }>
}

export interface IFilter {
  label: string,
  formattedLabel: string,
  type: string,
  items: Array<string>,
}
