<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'abbreviations'"
      title="Abbreviations"
      :headers="headers"
      :service="service"
      index="abbreviation"
      fill-height
      hide-collapsable
    >
      <template #item.caseSensitive="{ item }">
        <v-chip
          :color="item.data.caseSensitive ? 'success' : null"
          label
          small
        >
          <span v-if="item.data.caseSensitive">Yes</span>
          <span v-else>No</span>
        </v-chip>
      </template>

      <template v-slot:item.trackerId="{ item }">
        <template v-if="item.data.trackerId">
          <v-chip
            v-if="item.data.trackerentity"
            :to="{ name: 'Tracker', params: {id: item.data.trackerentity.data.id }}"
            label
          >
            {{item.data.trackerentity.data.label || 'Tracker #' + item.data.trackerentity.data.id}}
          </v-chip>
          <v-chip v-else color="warning" label>
            Not found
          </v-chip>
        </template>
        <template v-else>
          - All trackers -
        </template>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import AbbreviationService from '@/services/abbreviation.service';

@Component({
  components: {
    ListView,
    DataTable
  }
})
export default class AbbreviationList extends Vue {

  service = AbbreviationService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'AbbreviationForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Tracker',
      align: 'start',
      sortable: true,
      value: 'trackerId',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
        operator: 'contains',
      }
    },
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
        operator: 'contains',
      }
    },
    {
      text: 'Case Sensitive',
      align: 'start',
      sortable: true,
      value: 'caseSensitive',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'boolean',
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date',
        operator: 'starts with',
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>
