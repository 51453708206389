<script lang="ts">
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import { IDefinition, ILegend } from '@/interfaces';
import ExcelJS from 'exceljs';

export type IExportToRow = string[];

@Component
export default class ExportHeatmapToMenu extends Vue {
  @VModel({ type: Array, default: () => ([]) }) rows!: {[key: string]: string}[]
  @Prop({ type: Array, default: () => ([]) }) headers!: any[]
  @Prop({ type: Array, default: () => ([]) }) definitions!: IDefinition[]
  @Prop({ type: String, default: 'Untitled' }) title!: string
  @Prop({ type: Array, default: () => ([]) }) legends!: Array<ILegend>;

  downloadAsExcel() {
    this.$emit('generating', 'Excel');
    setTimeout(() => {
      const rows = this.getRows();
      const headers = this.getHeaders();
      const workbook = new ExcelJS.Workbook();
      workbook.creator = 'GlosaRx';
      workbook.created = new Date();

      const sheet = workbook.addWorksheet(this.title);
      const columns: any[] = [];
      headers.forEach(header => {
        columns.push({
          name: header,
        });
      })

      sheet.addTable({
        name: 'Heatmap',
        ref: 'A1',
        columns,
        rows: rows.map(row => [row[0], ...row.slice(1).map(() => '')]),
      });

      const headerItem = sheet.getRow(1);
      headerItem.height = 50;
      headerItem.alignment = {
        vertical: 'middle',
        wrapText: true,
      };
      headerItem.font = {
        size: 8,
      }
      headerItem.eachCell(cell => {
        const color = '00000000';
        cell.fill = {
          type: 'pattern',
          pattern: 'gray125',
          fgColor: { argb: color },
          bgColor: { argb: color }
        }
        cell.font = {
          size: 8,
          color: { argb: 'FFFFFF' },
        }
      })
      sheet.columns.forEach((column, columnIdx) => {
        column.width = columnIdx === 0 ? 65 : 10;
      });

      rows.forEach((row, rowIdx) => {
        const rowItem = sheet.getRow(rowIdx + 2);
        rowItem.alignment = {
          vertical: 'middle',
          wrapText: true,
        };
        rowItem.height = 50;
        headers.forEach((header, headerIdx) => {
          if (headerIdx === 0) {
            return;
          }
          const cell = rowItem.getCell(headerIdx + 1);
          const color = row[headerIdx].substring(1, 7);
          cell.font = {
            color: { argb: 'FFFFFF' },
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
          cell.fill = {
            type: 'pattern',
            pattern: 'gray125',
            fgColor: { argb: color },
            bgColor: { argb: color }
          }
        })
      });

      sheet.addRow([]);
      const legendRow = sheet.addRow(['Legends:']);
      legendRow.alignment = {
        horizontal: 'right',
        wrapText: true,
      };
      this.legends.forEach(legend => {
        const rowItem = sheet.addRow([legend.text]);
        const cell = rowItem.getCell(2);
        const color = legend.color.substring(1, 7);
        if (legend.dark) {
          cell.font = {
            color: { argb: 'FFFFFF' },
          }
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'gray125',
          fgColor: { argb: color },
          bgColor: { argb: color }
        }
        rowItem.alignment = {
          horizontal: 'right',
          wrapText: true,
        };
      })

      workbook.xlsx.writeBuffer().then(buffer => {
        // @ts-ignore
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.title + '.xlsx';
        link.click();
        link.remove();
        this.$emit('generating', false);
      });
    })
  }

  getHeaders(skipPrefix = true): string[] {
    return this.headers
      .map(header => skipPrefix ? header.text : header.value);
  }

  getRows(): IExportToRow[] {
    const headers = this.getHeaders(false);
    const rows: IExportToRow[] = [];
    this.rows.forEach(row => {
      const item: IExportToRow = []
      headers.forEach((header, headerIdx) => {
        const splitValue = row[header].split('_');
        if ((splitValue[1] || '').startsWith('#')) {
          item[headerIdx] = splitValue[1];
        } else {
          item[headerIdx] = row[header];
        }
      })
      rows.push(item);
    });
    return rows;
  }
}
</script>

<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="{ ...$attrs, ...attrs }" v-on="on">
        Export as...
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="downloadAsExcel">
        <v-list-item-icon>
          <v-icon>mdi-file-excel-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Excel</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
