<template>
  <v-app v-test-id="'app'">

    <!-- ARE YOU STILL THERE? MODAL -->
    <StillAliveModal
      :delay="1000 * 60 * 15"
      :enabled="stillAliveEnabled"
    />

    <!-- CHANGE LOGS MODAL -->
    <ChangeLogModal
      v-model="changelogs"
    />

    <Sidebar
      v-if="showSidebar"
      v-model="menuList"
    />

    <Toolbar
      :logo="require('@/assets/images/logo.svg')"
      :show-my-account="!showSidebar"
    />

    <v-main>
      <v-container fluid class="background fill-height align-start">
        <router-view :key="$route.fullPath" class="w-100"></router-view>
      </v-container>
    </v-main>

    <v-footer padless fixed app inset style="border-top: rgba(0,0,0,.1) solid 1px">
      <div class="caption pa-1 text-center w-100">
        {{ 'Copyright © 2023-' + new Date().getFullYear() }} YolaRx Consultant Inc. — <strong>All rights reserved.</strong>
      </div>
    </v-footer>

    <global-confetti/>
    <global-overlay/>
    <global-snack top app/>
    <global-modal scrollable/>
    <global-prompt/>
    <should-take-action/>
  </v-app>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
import Identity from '@/modules/sdk/core/identity'
import GlobalSnack from '@/modules/common/components/GlobalSnack.vue'
import GlobalOverlay from '@/modules/common/components/GlobalOverlay.vue'
import GlobalPrompt from '@/modules/common/components/GlobalPrompt.vue'
import GlobalModal from '@/modules/common/components/GlobalModal.vue'
import ShouldTakeAction from '@/modules/common/components/ShouldTakeAction.vue'
import GlobalConfetti from '@/modules/common/components/GlobalConfetti.vue'
import StillAliveModal from '@/modules/common/components/StillAliveModal.vue';
import ChangeLogModal from '@/modules/common/components/ChangeLogModal.vue';
import Sidebar, {ISidebarGroup} from '@/modules/common/components/Sidebar.vue';
import Toolbar from '@/modules/common/components/Toolbar.vue';
import changelogs from '@/changelogs'

@Component({
  components: {
    GlobalConfetti,
    GlobalOverlay,
    GlobalSnack,
    GlobalModal,
    GlobalPrompt,
    ShouldTakeAction,
    StillAliveModal,
    ChangeLogModal,
    Sidebar,
    Toolbar,
  }
})
export default class App extends Vue {

  changelogs = changelogs
  stillAliveEnabled = !Identity.hasRole(['dev'])

  get showSidebar(): boolean {
    return !Identity.hasRole(['client'])
      || (Identity.getIdentity()?.loggedInAs || false);
  }

  get menuList(): ISidebarGroup[] {

    const dev = Identity.hasRole(['dev'])
    const admin = Identity.hasRole(['admin'])
    const leader = Identity.hasRole(['leader'])
    const arbitrator = Identity.hasRole(['arbitrator'])
    const researcher = Identity.hasRole(['researcher'])
    const tracker = Identity.hasRole(['tracker'])
    const viewer = Identity.hasRole(['viewer'])
    const client = Identity.hasRole(['client'])

    const items: ISidebarGroup[] = [
      {
        children: [
          {
            title: 'Dashboard',
            to: { name: 'TrackerView' },
            icon: 'mdi-map-search',
            show: dev || admin || tracker || client
          },
        ]
      },

      // Admin
      {
        show: admin || dev || leader || tracker,
        label: this.$i18n.t('sidebar.admin'),
        children: [
          { title: 'Trackers', to: { name: 'TrackerList' }, icon: 'mdi-file-chart-outline', show: dev || admin || tracker},
        ]
      },

      // Settings
      {
        show: dev || admin || leader,
        label: this.$i18n.t('sidebar.settings'),
        children: [
          { title: 'Countries', to: { name: 'CountryList' }, icon: 'mdi-earth', show: dev || admin },
        ]
      },

      // Managements
      {
        show: dev || admin,
        label: this.$i18n.t('sidebar.management'),
        children: [
          { title: 'Users', to: { name: 'UserList' }, icon: 'mdi-account-outline', show: dev || admin },
          { title: 'Roles', to: { name: 'RoleList' }, icon: 'mdi-briefcase-account-outline', show: dev },
          { title: 'Abbreviations', to: { name: 'AbbreviationList' }, icon: 'mdi-code-block-tags', show: dev || admin },
          { title: 'Notifications', to: { name: 'NotificationList' }, icon: 'mdi-bell-outline', show: dev || admin },
          { title: 'Templates', to: { name: 'TemplateList' }, icon: 'mdi-clipboard-text', show: dev || admin },
          { title: 'Video Library', to: { name: 'VideoLibraryList' }, icon: 'mdi-video-outline', show: dev || admin },
        ]
      },

      // Managements
      {
        show: dev || admin,
        label: this.$i18n.t('sidebar.inscriptions'),
        children: [
          { title: 'Contacts', to: { name: 'ContactList' }, icon: 'mdi-comment-multiple-outline', show: dev || admin },
          { title: 'Satisfactions', to: { name: 'SatisfactionList' }, icon: 'mdi-certificate', show: dev || admin },
        ]
      },

      // Dev
      {
        show: dev,
        label: this.$i18n.t('sidebar.dev'),
        children: [
          { title: 'Sandbox', to: { name: 'DevView' }, icon: 'mdi-developer-board' },
        ]
      },
    ]

    return items.filter(item => ((item.children || []).length > 0 || item.label === undefined) && item.show !== false).map(item => {
      item.children = item.children.filter(child => child.show !== false)
      return item
    })
  }
}
</script>
