var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex align-center py-2",staticStyle:{"gap":"0.5rem"}},_vm._l((_vm.getDifferences(_vm.firstValue, _vm.secondValue, _vm.header)),function(diff,diffIdx){return _c('v-chip',{key:diffIdx + '_' + diff,attrs:{"color":diff.removed
        ? 'error'
        : diff.added
          ? 'success'
          : !diff.equals
            ? 'warning'
            : null,"label":"","small":""}},[_c(diff.removed ? 's' : 'span',{tag:"component"},[_vm._v(_vm._s(diff.value))])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }