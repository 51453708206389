<template>
  <v-app v-test-id="'app-auth'">
    <v-main>
      <ModalDialog
        v-test-id="'terms-modal'"
        v-model="termsModal"
        :title="$t('auth.termsModal.title')"
        :buttons="[{ text: $t('btn.close'), attrs: { outlined: true }, events: { click: () => { this.termsModal = false } } }]"
        :persistent="showTermsApplyButton"
        icon="mdi-gavel"
        max-width="600"
        scrollable
        background-color="background"
        @scroll-to-bottom="onScrollToBottom"
      >
        <template #body>
          <div class="mt-5 pt-1">
            <span v-html="$t('auth.termsModal.body')"></span>
          </div>
        </template>
        <template #buttons>
          <v-spacer></v-spacer>
          <v-btn
            v-if="showTermsApplyButton"
            :disabled="!scrollToBottom || acceptTermsLoading"
            :loading="acceptTermsLoading"
            large
            color="primary"
            @click="onAcceptTerms"
          >
            Accept
          </v-btn>
          <v-btn
            outlined
            large
            @click="termsModal = false"
          >
            Close
          </v-btn>
        </template>
      </ModalDialog>
      <v-sheet
        v-test-id="'terms-modal-content'"
        class="h-100 d-flex flex-column py-4 py-md-0"
        :color="backgroundColor"
        :style="styles"
      >
        <v-sheet
          color="transparent"
          class="h-100 d-flex align-center justify-center"
          style="flex: 1"
        >
          <v-card
            outlined
            elevation="15"
            class="text-center w-100"
            :max-width="maxWidth"
            :tile="flat"
            :flat="flat"
          >
            <!-- DARK MODE -->
            <theme-toggle
              :btn-attrs="{
                style: 'position: absolute; right: 0; z-index: 1',
                class: 'mr-4 mt-3',
              }"
            />

            <!-- LOGO & TITLE -->
            <v-card-text class="d-flex flex-column align-center">
              <v-img
                :src="logo"
                :style="$vuetify.theme.dark ? 'filter: invert(1)' : null"
                contain
                alt="Logo"
                height="66"
              />
              <h1
                class="primary--text display-1 mt-2 mb-n3"
                style="flex: 1" v-text="$t('auth.title.' + $route.name) || 'YolaRx'"
                v-if="$route.name !== 'login'"
              ></h1>
            </v-card-text>

            <!-- AUTH FORM -->
            <v-card-text>
              <router-view @terms="onTerms"/>
            </v-card-text>

          </v-card>
        </v-sheet>

        <v-footer
          :color="backgroundColor"
          class="d-block d-md-flex text-center justify-md-space-between justify-center caption"
        >
          <span v-text="$t('footer.copyright', {name: 'YolaRx Consultant Inc.', year})"></span>
          <span v-text="$t('footer.version', {version})"></span>
        </v-footer>
      </v-sheet>
    </v-main>
    <global-snack top app/>
  </v-app>
</template>

<script type="ts">
import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
import ModalDialog from '@/modules/common/components/ModalDialog'
import GlobalSnack from '@/modules/common/components/GlobalSnack'
import ThemeToggle from '@/modules/common/components/ThemeToggle.vue'
import logo from '@/assets/images/logo.svg'
import background from '@/assets/images/auth-background.jpg'

@Component({
  components: {
    ModalDialog,
    GlobalSnack,
    ThemeToggle,
  },
})
export default class Auth extends Vue {
  logo = logo
  background = background
  version = process.env.VUE_APP_PACKAGE_VERSION
  year = new Date().getFullYear()
  termsModal = false
  scrollToBottom = false
  showTermsApplyButton = false
  acceptTermsPromise = null
  acceptTermsLoading = false

  get flat() {
    return this.$vuetify.breakpoint.smAndDown
  }

  get backgroundColor() {
    return this.$vuetify.breakpoint.smAndDown ? 'white' : 'background'
  }

  get styles() {
    return {
      backgroundImage: 'url(' + background + ')',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center right',
    }
  }

  get maxWidth() {
    return this.$vuetify.breakpoint.mdAndUp ? 350 : null
  }

  onTerms(args = null) {
    this.termsModal = true;
    this.showTermsApplyButton = args !== null;
    this.acceptTermsPromise = args;
  }

  onScrollToBottom() {
    this.scrollToBottom = true;
  }

  onAcceptTerms() {
    this.acceptTermsLoading = true;
    this.acceptTermsPromise()
      .then(() => this.termsModal = false)
      .finally(() => this.acceptTermsLoading = false)
  }
}
</script>
